<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <!-- Column 1: Address -->
        <div class="col-md-4 footer-column">
          <p>&copy; 2023 IVIS LABS Private Limited. All Rights Reserved.</p>
          <p><i class="fas fa-location-dot"></i>259-B, Vidwath Campus, Meenakunte, Hebbal Industrial area, Mysuru, India - 570018</p>
        </div>

        <!-- Column 2: Contact Details -->
        <div class="col-md-4 footer-column">
          <p><i class="fas fa-envelope"></i> <a href="mailto:info@ivislabs.com">info@ivislabs.com</a></p>
          <p><i class="fas fa-phone-alt"></i> <a href="tel:+916364411444">+91 6364411444</a></p>
        </div>

        <!-- Column 3: WhatsApp -->
        <div class="col-md-4 footer-column">
          <p>
            
            <i class="fab fa-whatsapp"></i> 
            <a href="https://wa.me/916364411444" target="_blank">
              Contact us on WhatsApp
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

  
  <script>
  import '@fortawesome/fontawesome-free/css/all.css';

  export default {
    name: 'FooTer',
    // You can add your JavaScript logic here if needed
  };
  </script>
  
  <style scoped>
  .footer {
    color: rgba(4, 24, 39, 0.9);
    background-color: white;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-column {
    margin-bottom: 15px;
  }
  
  .footer a {
    color: rgba(4, 24, 39, 1);    text-decoration: none;
    position: relative;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .footer i {
    margin-right: 8px;
  }
  
  @media (min-width: 768px) {
    .footer {
      text-align: left;

    }
  
    .footer-column {
      text-align: left;
    }
  }
  </style>
   