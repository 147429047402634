// router/index.js
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  { path: '/', component: () => import('../views/Home.vue') },
  { path: '/about', component: () => import('../views/About.vue') },
  { path: '/solutions', component: () => import('../views/Solutions.vue') },
  { path: '/products', component: () => import('../views/Products.vue') },
  {
    path: '/learning',
    name: 'Academia',
    component: () => import('@/views/Learning.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/diploma-internship-application',
    name: 'InternshipForm',
    component: () => import('../views/DiplomaInternshipForm.vue')
  }
  
  
  // ... other routes
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
