<template>
  <nav class="navbar navbar-expand-lg navbar-dark" :class="{'bg-transparent': isCollapsed && !scrolled, 'bg-white': !isCollapsed || scrolled}">
    <div class="container-fluid">
        <router-link class="navbar-brand" to="/">
            <img src="https://ivislabs.s3.ap-south-1.amazonaws.com/facebook_cover_photo_1.png" width="100"/>
          </router-link>
        <button class="navbar-toggler" type="button" @click="toggleCollapse" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
          <div class="navbar-nav">
            <router-link to="/solutions" class="nav-link">Our Solutions</router-link>
            <router-link class="nav-link" to="/products">Products</router-link>
            <router-link class="nav-link" to="/learning">Academia</router-link>
            <router-link class="nav-link" to="/about">About Us</router-link>
            
          </div>
        </div>
      </div>
    </nav>
</template>
  
<script>
export default {
  name: 'NavBar',
  data() {
    return {
      scrolled: false,
      isCollapsed: true,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    }
  },

};
</script>
  
<style>
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s; /* Smooth transition for background color */
}

/* Targeting links in the navbar */
.navbar .navbar-nav .nav-link {
  color: white !important; /* Force the text color */
}

/* Default style when navbar is transparent */
.navbar.bg-transparent .navbar-nav .nav-link {
  color: white !important;
}
.navbar.bg-transparent .navbar-toggler-icon {
  /* Style for toggler icon when navbar is transparent */
}

/* Style when navbar is white */
.navbar.bg-white {
  background-color: white !important;
}
.navbar.bg-white .navbar-nav .nav-link {
  color: rgba(4, 24, 39, 0.9) !important;
}
.navbar.bg-white .navbar-toggler-icon {
  /* Style for toggler icon when navbar is white */
}

/* Hover styles */
.navbar .navbar-nav .nav-link:hover {
  color: #ddd !important;
}</style>


