<template>
  <div class="d-flex flex-column min-vh-100">

    <Navbar/>
    
    <div class="flex-grow-1">
      <RouterView />
    </div>
    <FooTer/>
</div>
</template>
<script>
import Navbar from './components/Navbar.vue';
import FooTer from './components/Footer.vue';

export default {
  components: {
    Navbar,
    FooTer
  }
};
</script>
<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content {
  flex: 1;
}
</style>
